import ApiService from "api";
import types from "./types";
import sharedTypes from "api/item-detail/types";
import sharedItemCreateTypes from "api/item-create/types";
import inventoryTypes from "api/inventory/types";
import { isObjectEmpty } from "api/utils/utils";

const INITIAL_STATE = {
  addData: [],
  createItemAddData: [],
  addDataResponse: [],
  addDataWithStatus: [],
  addDataShowSnackBar: false,
  showConfirmationModal: false,
  showShareConfirmationModal: false,
  lastDataAdded: false,
  showAddDataConfirmationModal: false,
  showAddDataStatusTable: false,
  showTransferSpinner: false,
  currentItem: {},
  accept: false,
  transferOwnerId: "",
  ownersData: [],
  transferItemResponse: [],
  recentTransferedItems: [],
  sharing: [],
  shareItemResponse: {},
  editShareItemResponse: {},
  deleteShareItemResponse: {},
  errorShareItemEdit: {},
  errorShareItemDelete: {},
  errorMessage: {},
  errorMessageItemDetails: {},
  errorTransferItem: {},
  errorShareItem: {},
  isScriptLoaded: false,
  loadingItem: false,
  sharingCount: 0,
  subscriptions: [],
  showSubscribeModal: false,
  subscribeResponse: {},
  errorSubscribe: {},
  unsubscribeResponse: {},
  errorUnsubscribe: {},
  tagSensor: "",
  tagSensorId: "",
  template: "UNISOT Defaults",
  hideTwinPictures: false,
  hideFoodForm: false,
  hideStatus: false,
  hideSystemData: false,
  storedGroups: [],
  allowSharing: false,
  sfcData: {
    "Vessel Name": {
      label: "Vessel Name",
      initValue: "Sterling White Halibut",
      value: "Sterling White Halibut",
      error: false,
      infoType: "mandatory",
    },
    "Vessel ID": {
      label: "Vessel ID",
      initValue: "7080004064508",
      value: "7080004064508",
      error: false,
      infoType: "mandatory",
    },
    "Producer Name": {
      label: "Producer Name",
      initValue: "Fonn Butchery",
      value: "Fonn Butchery",
      error: false,
      infoType: "mandatory",
    },
    "Producer ID": {
      label: "Producer ID",
      initValue: "7080003142726",
      value: "7080003142726",
      error: false,
      infoType: "mandatory",
    },
    EUEFTA: {
      label: "EUEFTA",
      initValue: "NORGE N2284",
      value: "NORGE N2284",
      error: false,
      infoType: "mandatory",
    },
    "Supplier Name": {
      label: "Supplier Name",
      initValue: "Reinhartsen",
      value: "Reinhartsen",
      error: false,
      infoType: "mandatory",
    },
    "Supplier ID": {
      label: "Supplier ID",
      initValue: "7080004064409",
      value: "7080004064409",
      error: false,
      infoType: "mandatory",
    },
    "Commercial Name": {
      label: "Commercial Name",
      initValue: "Atlantic Halibut/Flétan de l'Atlantique/Fletán del Atlántico",
      value: "Atlantic Halibut/Flétan de l'Atlantique/Fletán del Atlántico",
      error: false,
      infoType: "mandatory",
    },
    "Product IDGTIN": {
      label: "Product IDGTIN",
      initValue: "97070428000056",
      value: "97070428000056",
      error: false,
      infoType: "mandatory",
    },
    "Species Code": {
      label: "Species Code",
      initValue: "HAL",
      value: "HAL",
      error: false,
      infoType: "mandatory",
    },
    "Scientific Name": {
      label: "Scientific Name",
      initValue: "Hippoglossus hippoglossus",
      value: "Hippoglossus hippoglossus",
      error: false,
      infoType: "mandatory",
    },
    Size: {
      label: "Size",
      value: "",
      error: false,
      infoType: "optional",
    },
    Quality: {
      label: "Quality",
      value: "",
      error: false,
      infoType: "optional",
    },
    PresentationState: {
      label: "Presentation State",
      value: "",
      error: false,
      infoType: "optional",
    },
    PreservationState: {
      label: "Preservation State",
      value: "",
      error: false,
      infoType: "optional",
    },
    "Storage Temperature Conditions": {
      label: "Storage Temperature Conditions",
      value: "",
      error: false,
      infoType: "optional",
    },
    "Batch Lot Number": {
      label: "Batch Lot Number",
      value: "",
      error: false,
      infoType: "optional",
    },
    "Harvest Date": {
      label: "Harvest Date",
      value: null,
      error: false,
      infoType: "optional",
    },
    "Production Date": {
      label: "Production Date",
      value: null,
      error: false,
      infoType: "optional",
    },
    "Use By Date": {
      label: "Use By Date",
      value: null,
      error: false,
      infoType: "optional",
    },
    "Production Method": {
      label: "Production Method",
      value: "",
      error: false,
      infoType: "optional",
    },
    "Catch Area": {
      label: "Catch Area",
      value: "",
      error: false,
      infoType: "optional",
    },
    Quantity: {
      label: "Quantity",
      value: "",
      error: false,
      infoType: "optional",
    },
    "Net Weight": {
      label: "Net Weight",
      value: "",
      error: false,
      infoType: "optional",
    },
    Box: {
      label: "Box",
      value: "",
      error: false,
      infoType: "optional",
    },
  },
  publicShareUser: "",
  receiveUploadFile: {},
  queryJob: {},
  fileUploads: [],
  errorReceiveUploadFile: {},
  saveTab: 0,
  packageDetails: {},
  prepareFileForDownload: {},
  queryDownloadJob: {},
  downloadFile: "",
};

const itemDetailsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // we need isScriptLoaded through whole app
    case sharedTypes.FETCH_ITEM_DETAIL: {
      return {
        ...state,
        loadingItem: true,
      };
    }
    case types.CLEAR_ITEM_DETAILS_STATE: {
      return {
        ...state,
        addData: [],
        createItemAddData: [],
        currentItem: {},
        addDataResponse: [],
        addDataWithStatus: [],
        addDataShowSnackBar: false,
        showConfirmationModal: false,
        showShareConfirmationModal: false,
        lastDataAdded: false,
        showAddDataConfirmationModal: false,
        showAddDataStatusTable: false,
        showTransferSpinner: false,
        ownersData: [],
        shareItemResponse: {},
        transferItemResponse: [],
        editShareItemResponse: {},
        deleteShareItemResponse: {},
        errorShareItemEdit: {},
        errorShareItemDelete: {},
        errorMessage: {},
        errorMessageItemDetails: {},
        errorTransferItem: {},
        errorShareItem: {},
        template: "UNISOT Defaults",
        showSubscribeModal: false,
        subscribeResponse: {},
        errorSubscribe: {},
        unsubscribeResponse: {},
        errorUnsubscribe: {},
        publicShareUser: "",
        receiveUploadFile: {},
        queryJob: {},
        fileUploads: [],
        errorReceiveUploadFile: {},
        packageDetails: {},
        prepareFileForDownload: {},
        queryDownloadJob: {},
        downloadFile: "",
        storedGroups: [],
        sfcData: {
          "Vessel Name": {
            label: "Vessel Name",
            initValue: "Sterling White Halibut",
            value: "Sterling White Halibut",
            error: false,
            infoType: "mandatory",
          },
          "Vessel ID": {
            label: "Vessel ID",
            initValue: "7080004064508",
            value: "7080004064508",
            error: false,
            infoType: "mandatory",
          },
          "Producer Name": {
            label: "Producer Name",
            initValue: "Fonn Butchery",
            value: "Fonn Butchery",
            error: false,
            infoType: "mandatory",
          },
          "Producer ID": {
            label: "Producer ID",
            initValue: "7080003142726",
            value: "7080003142726",
            error: false,
            infoType: "mandatory",
          },
          EUEFTA: {
            label: "EUEFTA",
            initValue: "NORGE N2284",
            value: "NORGE N2284",
            error: false,
            infoType: "mandatory",
          },
          "Supplier Name": {
            label: "Supplier Name",
            initValue: "Reinhartsen",
            value: "Reinhartsen",
            error: false,
            infoType: "mandatory",
          },
          "Supplier ID": {
            label: "Supplier ID",
            initValue: "7080004064409",
            value: "7080004064409",
            error: false,
            infoType: "mandatory",
          },
          "Commercial Name": {
            label: "Commercial Name",
            initValue:
              "Atlantic Halibut/Flétan de l'Atlantique/Fletán del Atlántico",
            value:
              "Atlantic Halibut/Flétan de l'Atlantique/Fletán del Atlántico",
            error: false,
            infoType: "mandatory",
          },
          "Product IDGTIN": {
            label: "Product IDGTIN",
            initValue: "97070428000056",
            value: "97070428000056",
            error: false,
            infoType: "mandatory",
          },
          "Species Code": {
            label: "Species Code",
            initValue: "HAL",
            value: "HAL",
            error: false,
            infoType: "mandatory",
          },
          "Scientific Name": {
            label: "Scientific Name",
            initValue: "Hippoglossus hippoglossus",
            value: "Hippoglossus hippoglossus",
            error: false,
            infoType: "mandatory",
          },
          Size: {
            label: "Size",
            value: "",
            error: false,
            infoType: "optional",
          },
          Quality: {
            label: "Quality",
            value: "",
            error: false,
            infoType: "optional",
          },
          PresentationState: {
            label: "Presentation State",
            value: "",
            error: false,
            infoType: "optional",
          },
          PreservationState: {
            label: "Preservation State",
            value: "",
            error: false,
            infoType: "optional",
          },
          "Storage Temperature Conditions": {
            label: "Storage Temperature Conditions",
            value: "",
            error: false,
            infoType: "optional",
          },
          "Batch Lot Number": {
            label: "Batch Lot Number",
            value: "",
            error: false,
            infoType: "optional",
          },
          "Harvest Date": {
            label: "Harvest Date",
            value: null,
            error: false,
            infoType: "optional",
          },
          "Production Date": {
            label: "Production Date",
            value: null,
            error: false,
            infoType: "optional",
          },
          "Use By Date": {
            label: "Use By Date",
            value: null,
            error: false,
            infoType: "optional",
          },
          "Production Method": {
            label: "Production Method",
            value: "",
            error: false,
            infoType: "optional",
          },
          "Catch Area": {
            label: "Catch Area",
            value: "",
            error: false,
            infoType: "optional",
          },
          Quantity: {
            label: "Quantity",
            value: "",
            error: false,
            infoType: "optional",
          },
          "Net Weight": {
            label: "Net Weight",
            value: "",
            error: false,
            infoType: "optional",
          },
          Box: {
            label: "Box",
            value: "",
            error: false,
            infoType: "optional",
          },
        },
      };
    }
    case types.ITEM_DETAILS_ADD_DATA_TO_TABLE: {
      const data = action.payload;
      return {
        ...state,
        addData: [...state.addData, data],
        createItemAddData: [...state.addData, data],
      };
    }
    case types.ITEM_DETAILS_ADD_FILE_UPLOAD: {
      return {
        ...state,
        fileUploads: [...state.fileUploads, action.payload],
      };
    }
    case types.ITEM_DETAILS_DELETE_DATA_FROM_ADD_DATA: {
      const dataToDelete = action.payload;
      const newAddData = state.addData.filter((items) => {
        let itemFound = false;
        dataToDelete.forEach((timestamp) => {
          if (timestamp === items.payload.timestamp) {
            itemFound = true;
          }
          if (
            items.type === "GeoJSON" &&
            timestamp === items.payload[0].properties.timestamp
          ) {
            itemFound = true;
          }
        });
        return itemFound === false;
      });
      return {
        ...state,
        addData: newAddData,
      };
    }
    case types.ITEM_DETAILS_DELETE_ALL_ADD_DATA: {
      return {
        ...state,
        addData: [],
        createItemAddData: [],
      };
    }
    case types.ITEM_DETALS_ADD_STATUS_FIELD: {
      const addDataEnriched = state.addData.map((data) => {
        return data.map((oneData, index) => {
          if (index === 0) {
            return { ...oneData, status: "Pending" };
          }
          return oneData;
        });
      });
      return {
        ...state,
        addDataWithStatus: addDataEnriched,
      };
    }
    case sharedItemCreateTypes.RECEIVE_ADD_ONE_DATA: {
      /*  let updatedSfc = {
        ...state.sfcData,
      };
      let addDataEnriched = [...state.addData];
      let fileUploadsEnriched = [...state.fileUploads];

      for (const resp of action.payload) {
        if ("reason" in resp) {
          if (resp.value.dataType === "SFCKeyValue") {
            updatedSfc[resp.value.data[0].key] = {
              ...state.sfcData[resp.value.data[0].key],
              status: "Failed",
            };
          } else if (resp.value.dataType === "GeoJSON") {
            addDataEnriched.map((oneData) => {
              if (oneData.type === "GeoJSON") {
                if (
                  oneData.payload[0].properties.timestamp ===
                  resp.value.data[0].properties.timestamp
                ) {
                  return { ...oneData, status: "Failed" };
                }
                return { ...oneData };
              }
              return { ...oneData };
            });
          } else if (resp.value.dataType === "senML") {
            addDataEnriched.map((oneData) => {
              if (oneData.type === "senML") {
                if (oneData.payload[0].t === resp.value.data[0].t) {
                  return { ...oneData, status: "Failed" };
                }
                return { ...oneData };
              }
              return { ...oneData };
            });
          } else if (resp.value.dataType === "FileUpload") {
            fileUploadsEnriched.map((oneData) => {
              if (oneData.type === "FileUpload") {
                if (
                  oneData.payload[0].dataLink === resp.value.data[0].dataLink
                ) {
                  return { ...oneData, status: "Failed" };
                }
                return { ...oneData };
              }
              return { ...oneData };
            });
          }
        } else {
          // success
          if (resp.value.dataType === "SFCKeyValue") {
            updatedSfc[resp.value.data[0].key] = {
              ...state.sfcData[resp.value.data[0].key],
              status: "Success",
            };
          } else if (resp.value.dataType === "GeoJSON") {
            addDataEnriched = addDataEnriched.map((oneData) => {
              if (oneData.type === "GeoJSON") {
                if (
                  oneData.payload[0].properties.timestamp ===
                  resp.value.data[0].properties.timestamp
                ) {
                  return { ...oneData, status: "Success" };
                }
                return { ...oneData };
              }
              return { ...oneData };
            });
          } else if (resp.value.dataType === "senML") {
            addDataEnriched = addDataEnriched.map((oneData) => {
              if (oneData.type === "senML") {
                if (oneData.payload[0].t === resp.value.data[0].t) {
                  return { ...oneData, status: "Success" };
                }
                return { ...oneData };
              }
              return { ...oneData };
            });
          } else if (resp.value.dataType === "FileUpload") {
            fileUploadsEnriched = fileUploadsEnriched.map((oneData) => {
              if (oneData.type === "FileUpload") {
                if (
                  oneData.payload[0].dataLink === resp.value.data[0].dataLink
                ) {
                  return { ...oneData, status: "Success" };
                }
                return { ...oneData };
              }
              return { ...oneData };
            });
          } else {
            addDataEnriched = addDataEnriched.map((oneData) => {
              if (oneData.payload.timestamp === resp.value.data.timestamp) {
                if (resp.status === "fulfilled") {
                  return { ...oneData, status: "Success" };
                } else {
                  return { ...oneData, status: "Failed" };
                }
              }
              return { ...oneData };
            });
          }
        }
      } */
      return {
        ...state,
        addData: action.payload,
        // sfcData: updatedSfc,
        // fileUploads: fileUploadsEnriched,
      };
    }
    case sharedItemCreateTypes.RECEIVE_ADD_ONE_DATA_FAILED: {
      for (const tx of action.data.transactions) {
        if (tx.type === "GeoJSON") {
          const addDataEnriched = state.addData.map((oneData) => {
            if (oneData.type === "GeoJSON") {
              if (
                oneData.payload[0].properties.timestamp ===
                tx.payload[0].properties.timestamp
              ) {
                return { ...oneData, status: "Failed" };
              }
              return { ...oneData };
            }
            return { ...oneData };
          });
          return {
            ...state,
            addData: addDataEnriched,
          };
        } else if (tx.type === "senML") {
          const addDataEnriched = state.addData.map((oneData) => {
            if (oneData.type === "senML") {
              if (oneData.payload[0].t === tx.payload[0].t) {
                return { ...oneData, status: "Failed" };
              }
              return { ...oneData };
            }
            return { ...oneData };
          });
          return {
            ...state,
            addData: addDataEnriched,
          };
        } else if (tx.type === "SFCKeyValue") {
          const updatedSfc = {
            ...state.sfcData,
            [tx.payload[0].key]: {
              ...state.sfcData[tx.payload[0].key],
              status: "Failed",
            },
          };
          return {
            ...state,
            sfcData: updatedSfc,
          };
        } else {
          return {
            ...state,
          };
        }
      }
      return {
        ...state,
      };
    }
    case types.ADD_SFC_DATA: {
      const key = action.payload.key;
      if ("value" in action.payload) {
        const value = action.payload.value;
        return {
          ...state,
          sfcData: {
            ...state.sfcData,
            [key]: { ...state.sfcData[key], value: value, error: false },
          },
        };
      }
      if ("error" in action.payload) {
        const error = action.payload.error;
        return {
          ...state,
          sfcData: {
            ...state.sfcData,
            [key]: { ...state.sfcData[key], error: error },
          },
        };
      }
      return {
        ...state,
      };
    }
    case types.ITEM_DETAILS_ADD_DATA_SHOW_SNACKBAR: {
      return {
        ...state,
        addDataShowSnackBar: action.payload,
      };
    }
    case types.ITEM_DETAILS_ADDED_LAST_DATA: {
      return {
        ...state,
        lastDataAdded: true,
      };
    }

    case sharedTypes.RECEIVE_TRANSFER_ITEM: {
      return {
        ...state,
        transferItemResponse: action.payload,
        recentTransferedItems: [...state.recentTransferedItems, action.payload],
      };
    }
    case sharedTypes.RECEIVE_TRANSFER_ITEM_FAILED: {
      return {
        ...state,
        errorTransferItem: action.payload[0],
        showTransferSpinner: false,
      };
    }
    case types.CLEAR_TRANSFER_STATE: {
      return {
        ...state,
        errorTransferItem: {},
      };
    }
    case types.ITEM_DETAILS_MODAL_CONFIRMATION: {
      const showConfirmationModal = action.payload;
      return {
        ...state,
        showConfirmationModal,
      };
    }

    case types.SHARE_MODAL_CONFIRMATION: {
      const showShareConfirmationModal = action.payload;
      return {
        ...state,
        showShareConfirmationModal,
      };
    }
    case sharedTypes.SHARE_ITEM_RESPONSE: {
      let shareItemResponse = action.payload;
      shareItemResponse.shareItem.dataLink = `${
        ApiService.getEnvironment().trackingAppUrl
      }${shareItemResponse.shareItem._id}/${
        shareItemResponse.shareItem.shareSecret
      }`;
      return {
        ...state,
        shareItemResponse,
      };
    }
    case sharedTypes.SHARE_ITEM_FAILED: {
      return {
        ...state,
        errorShareItem: action.error,
      };
    }
    case sharedTypes.SHARING_COUNT_RESPONSE: {
      return {
        ...state,
        sharingCount: action.payload.count,
      };
    }
    case sharedTypes.EDIT_SHARE_ITEM_RESPONSE: {
      const editShareItemResponse = action.payload;
      return {
        ...state,
        editShareItemResponse,
      };
    }
    case sharedTypes.EDIT_SHARE_ITEM_FAILED: {
      return {
        ...state,
        errorShareItemEdit: action.error,
      };
    }
    case sharedTypes.DELETE_SHARE_ITEM_RESPONSE: {
      return {
        ...state,
        deleteShareItemResponse: action.payload,
      };
    }
    case sharedTypes.DELETE_SHARE_ITEM_FAILED: {
      return {
        ...state,
        errorShareItemDelete: action.error,
      };
    }
    case sharedTypes.SHARED_ENTRIES_RESPONSE: {
      return {
        ...state,
        sharing: action.payload.map((share) => {
          share.dataLink = `${ApiService.getEnvironment().trackingAppUrl}${
            share._id
          }/${share.shareSecret}`;
          return share;
        }),
      };
    }
    case types.ITEM_DETAILS_ADD_DATA_MODAL_CONFIRMATION: {
      const showAddDataConfirmationModal = action.payload;
      return {
        ...state,
        showAddDataConfirmationModal,
      };
    }
    case types.ITEM_DETAILS_OWNER_ID: {
      const transferOwnerId = action.payload;
      return {
        ...state,
        transferOwnerId,
      };
    }
    case types.ITEM_DETAILS_OWNERS_DATA: {
      const ownersData = action.payload;
      return {
        ...state,
        ownersData,
      };
    }

    case sharedTypes.RECEIVE_ITEM_DETAIL: {
      let currentItem = action.payload;
      if (!isObjectEmpty(currentItem)) {
        const rootUpdates = currentItem.data.filter(
          (dt) => dt.dataType === "root-update"
        );
        if (rootUpdates.length > 0) {
          const sorted = rootUpdates.sort(
            (a, b) =>
              new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
          );
          currentItem["classification"] = sorted[0].data.values[0].value;
        }
      }
      currentItem.accept = "";
      return {
        ...state,
        currentItem,
        loadingItem: false,
      };
    }
    case inventoryTypes.CONSOLIDATE: {
      return {
        ...state,
        accept: action.payload.accept,
      };
    }
    case inventoryTypes.RECEIVE_CONSOLIDATE: {
      if (!isObjectEmpty(state.currentItem)) {
        return {
          ...state,
          currentItem: { ...state.currentItem, accept: state.accept },
        };
      } else {
        return state;
      }
    }
    case sharedTypes.RECEIVE_ITEM_DETAIL_FAILED: {
      return {
        ...state,
        errorMessageItemDetails: action.error,
      };
    }
    case types.ITEM_DETAILS_SHOW_ADD_DATA_STATUS_TABLE: {
      return {
        ...state,
        showAddDataStatusTable: action.payload,
      };
    }
    case types.ITEM_DETAILS_DELETE_ADD_DATA_RESPONSE: {
      return {
        ...state,
        addDataResponse: [],
      };
    }
    case types.ITEM_DETAILS_SHOW_TRANSFER_SPINNER: {
      return {
        ...state,
        showTransferSpinner: action.payload,
      };
    }
    case types.SCRIPT_LOADED: {
      return {
        ...state,
        isScriptLoaded: action.payload,
      };
    }
    case types.CLEAR_ADD_DATA: {
      return {
        ...state,
        addData: [],
        createItemAddData: [],
      };
    }
    case types.DELETE_ALL_SFC_INPUTS: {
      return {
        ...state,
        sfcData: {
          "Vessel Name": {
            label: "Vessel Name",
            initValue: "Sterling White Halibut",
            value: "Sterling White Halibut",
            error: false,
            infoType: "mandatory",
          },
          "Vessel ID": {
            label: "Vessel ID",
            initValue: "7080004064508",
            value: "7080004064508",
            error: false,
            infoType: "mandatory",
          },
          "Producer Name": {
            label: "Producer Name",
            initValue: "Fonn Butchery",
            value: "Fonn Butchery",
            error: false,
            infoType: "mandatory",
          },
          "Producer ID": {
            label: "Producer ID",
            initValue: "7080003142726",
            value: "7080003142726",
            error: false,
            infoType: "mandatory",
          },
          EUEFTA: {
            label: "EUEFTA",
            initValue: "NORGE N2284",
            value: "NORGE N2284",
            error: false,
            infoType: "mandatory",
          },
          "Supplier Name": {
            label: "Supplier Name",
            initValue: "Reinhartsen",
            value: "Reinhartsen",
            error: false,
            infoType: "mandatory",
          },
          "Supplier ID": {
            label: "Supplier ID",
            initValue: "7080004064409",
            value: "7080004064409",
            error: false,
            infoType: "mandatory",
          },
          "Commercial Name": {
            label: "Commercial Name",
            initValue:
              "Atlantic Halibut/Flétan de l'Atlantique/Fletán del Atlántico",
            value:
              "Atlantic Halibut/Flétan de l'Atlantique/Fletán del Atlántico",
            error: false,
            infoType: "mandatory",
          },
          "Product IDGTIN": {
            label: "Product IDGTIN",
            initValue: "97070428000056",
            value: "97070428000056",
            error: false,
            infoType: "mandatory",
          },
          "Species Code": {
            label: "Species Code",
            initValue: "HAL",
            value: "HAL",
            error: false,
            infoType: "mandatory",
          },
          "Scientific Name": {
            label: "Scientific Name",
            initValue: "Hippoglossus hippoglossus",
            value: "Hippoglossus hippoglossus",
            error: false,
            infoType: "mandatory",
          },
          Size: {
            label: "Size",
            value: "",
            error: false,
            infoType: "optional",
          },
          Quality: {
            label: "Quality",
            value: "",
            error: false,
            infoType: "optional",
          },
          PresentationState: {
            label: "Presentation State",
            value: "",
            error: false,
            infoType: "optional",
          },
          PreservationState: {
            label: "Preservation State",
            value: "",
            error: false,
            infoType: "optional",
          },
          "Storage Temperature Conditions": {
            label: "Storage Temperature Conditions",
            value: "",
            error: false,
            infoType: "optional",
          },
          "Batch Lot Number": {
            label: "Batch Lot Number",
            value: "",
            error: false,
            infoType: "optional",
          },
          "Harvest Date": {
            label: "Harvest Date",
            value: null,
            error: false,
            infoType: "optional",
          },
          "Production Date": {
            label: "Production Date",
            value: null,
            error: false,
            infoType: "optional",
          },
          "Use By Date": {
            label: "Use By Date",
            value: null,
            error: false,
            infoType: "optional",
          },
          "Production Method": {
            label: "Production Method",
            value: "",
            error: false,
            infoType: "optional",
          },
          "Catch Area": {
            label: "Catch Area",
            value: "",
            error: false,
            infoType: "optional",
          },
          Quantity: {
            label: "Quantity",
            value: "",
            error: false,
            infoType: "optional",
          },
          "Net Weight": {
            label: "Net Weight",
            value: "",
            error: false,
            infoType: "optional",
          },
          Box: {
            label: "Box",
            value: "",
            error: false,
            infoType: "optional",
          },
        },
      };
    }
    case types.CHANGE_SFC_INPUTS_STATUS: {
      const key = action.payload.key;
      const status = action.payload.status;
      return {
        ...state,
        sfcData: {
          ...state.sfcData,
          [key]: {
            ...state.sfcData[key],
            status,
          },
        },
      };
    }
    case types.CHANGE_PRODUCT_DATA_STATUS: {
      const item = action.payload.item;
      const status = action.payload.status;
      if (item.type === "GeoJSON") {
        const addDataEnriched = state.addData.map((oneData) => {
          if (oneData.type === "GeoJSON") {
            if (
              oneData.payload[0].properties.timestamp ===
              item.payload[0].properties.timestamp
            ) {
              return { ...oneData, status };
            }
            return { ...oneData };
          }
          return { ...oneData };
        });
        return {
          ...state,
          addData: addDataEnriched,
        };
      } else if (item.type === "senML") {
        const addDataEnriched = state.addData.map((oneData) => {
          if (oneData.type === "senML") {
            if (oneData.payload[0].t === item.payload[0].t) {
              return { ...oneData, status };
            }
            return { ...oneData };
          }
          return { ...oneData };
        });
        return {
          ...state,
          addData: addDataEnriched,
        };
      } else {
        return {
          ...state,
        };
      }
    }
    case sharedItemCreateTypes.SET_TEMPLATE: {
      const template = action.payload;
      return {
        ...state,
        template,
      };
    }
    case sharedTypes.GET_SUBSCRIPTIONS_RESPONSE: {
      return {
        ...state,
        subscriptions: action.payload,
      };
    }
    case sharedTypes.GET_SUBSCRIPTIONS_FAILED: {
      return {
        ...state,
        subscriptionsError: action.error,
      };
    }
    case types.TAG_SENSOR_INPUT: {
      return {
        ...state,
        tagSensor: action.payload,
      };
    }
    case types.TAG_SENSOR_ID_INPUT: {
      return {
        ...state,
        tagSensorId: action.payload,
      };
    }
    case sharedTypes.SUBSCRIBE: {
      return {
        ...state,
        errorSubscribe: {},
      };
    }
    case sharedTypes.UNSUBSCRIBE: {
      return {
        ...state,
        errorUnsubscribe: {},
      };
    }
    case sharedTypes.SUBSCRIBE_RESPONSE: {
      return {
        ...state,
        subscribeResponse: action.payload,
      };
    }
    case sharedTypes.SUBSCRIBE_FAILED: {
      return {
        ...state,
        errorSubscribe: action.error,
      };
    }
    case types.SHOW_SUBSCRIBE_MODAL: {
      return {
        ...state,
        showSubscribeModal: action.payload,
      };
    }
    case sharedTypes.UNSUBSCRIBE_RESPONSE: {
      return {
        ...state,
        unsubscribeResponse: action.payload,
      };
    }
    case sharedTypes.UNSUBSCRIBE_FAILED: {
      return {
        ...state,
        errorUnsubscribe: action.error,
      };
    }
    case sharedTypes.RECEIVE_PUBLIC_SHARE_USER: {
      return {
        ...state,
        publicShareUser: action.payload,
      };
    }
    case sharedTypes.RECEIVE_UPLOAD_FILE: {
      return {
        ...state,
        receiveUploadFile: action.payload,
      };
    }
    case sharedTypes.RECEIVE_UPLOAD_FILE_FAILED: {
      return {
        ...state,
        errorReceiveUploadFile: action.error,
      };
    }
    case sharedTypes.RECEIVE_LATEST_JOB_STATUS: {
      if (!isObjectEmpty(state.packageDetails)) {
        return {
          ...state,
          queryDownloadJob: action.payload,
        };
      } else {
        return {
          ...state,
          queryJob: action.payload,
        };
      }
    }
    case types.CLEAR_FILE_UPLOAD_STATE: {
      return {
        ...state,
        publicShareUser: "",
        receiveUploadFile: {},
        queryJob: {},
        fileUploads: [],
        errorReceiveUploadFile: {},
      };
    }
    case types.SAVE_TAB: {
      return {
        ...state,
        saveTab: action.payload,
      };
    }
    case sharedTypes.RECEIVE_GET_PACKAGE_DETAILS: {
      return {
        ...state,
        packageDetails: action.payload,
      };
    }
    case sharedTypes.RECEIVE_PREPARE_FILE_FOR_DOWNLOAD: {
      return {
        ...state,
        prepareFileForDownload: action.payload,
      };
    }
    case sharedTypes.RECEIVE_DOWNLOAD_FILE: {
      return {
        ...state,
        downloadFile: window.URL.createObjectURL(action.payload),
      };
    }
    case types.CLEAR_DOWNLOAD: {
      return {
        ...state,
        packageDetails: {},
        prepareFileForDownload: {},
        queryDownloadJob: {},
        downloadFile: "",
      };
    }
    case types.HIDE_TWIN_PICTURES: {
      return {
        ...state,
        hideTwinPictures: action.payload,
      };
    }
    case types.HIDE_FOOD_FORM: {
      return {
        ...state,
        hideFoodForm: action.payload,
      };
    }
    case types.HIDE_STATUS: {
      return {
        ...state,
        hideStatus: action.payload,
      };
    }
    case types.HIDE_DATA: {
      return {
        ...state,
        hideSystemData: action.payload,
      };
    }
    case types.STORE_GROUPS: {
      return {
        ...state,
        storedGroups: action.payload,
      };
    }
    case types.ALLOW_SHARING: {
      return {
        ...state,
        allowSharing: action.payload,
      };
    }
    default:
      return state;
  }
};

export default itemDetailsReducer;
