import types from "./types";

// ========== Add data to table ==========
export const addDataToTable = (data) => {
  return {
    type: types.ITEM_DETAILS_ADD_DATA_TO_TABLE,
    payload: data,
  };
};

// ========= delete data from Add data table ==========
export const deleteDataFromAddData = (data) => {
  return {
    type: types.ITEM_DETAILS_DELETE_DATA_FROM_ADD_DATA,
    payload: data,
  };
};

export const deleteAllAddData = () => {
  return {
    type: types.ITEM_DETAILS_DELETE_ALL_ADD_DATA,
  };
};

export const lastDataAdded = () => {
  return {
    type: types.ITEM_DETAILS_ADDED_LAST_DATA,
  };
};

export const showAddDataStatusTable = (bool) => {
  return {
    type: types.ITEM_DETAILS_SHOW_ADD_DATA_STATUS_TABLE,
    payload: bool,
  };
};

// ========== Add data to item request ==========
export const addDataRequest = (data) => {
  return {
    type: types.ITEM_DETAILS_ADD_DATA_REQUEST,
    payload: data,
  };
};

// ========== add data confirmation modal ==========
export const showAddDataConfirmationModal = (bool) => {
  return {
    type: types.ITEM_DETAILS_ADD_DATA_MODAL_CONFIRMATION,
    payload: bool,
  };
};

// ========== snackbar ===========
export const addDataShowSnackBar = (bool) => {
  return {
    type: types.ITEM_DETAILS_ADD_DATA_SHOW_SNACKBAR,
    payload: bool,
  };
};

// ========== share item confirmation modal ===========
export const showShareItemConfirmationModal = (bool) => {
  return {
    type: types.SHARE_MODAL_CONFIRMATION,
    payload: bool,
  };
};

// ========== transfer item confirmation modal ===========
export const showTransferItemConfirmationModal = (bool) => {
  return {
    type: types.ITEM_DETAILS_MODAL_CONFIRMATION,
    payload: bool,
  };
};

export const showTransferSpinner = (bool) => {
  return {
    type: types.ITEM_DETAILS_SHOW_TRANSFER_SPINNER,
    payload: bool,
  };
};

// ========== transfer owner id ==========
export const transferOwnerIdAction = (id) => {
  return {
    type: types.ITEM_DETAILS_OWNER_ID,
    payload: id,
  };
};

// ========== owners data ==========
export const saveOwnersData = (data) => {
  return {
    type: types.ITEM_DETAILS_OWNERS_DATA,
    payload: data,
  };
};

// =========
export const addStatusField = () => {
  return {
    type: types.ITEM_DETALS_ADD_STATUS_FIELD,
  };
};

export const deleteAddDataResponse = () => {
  return {
    type: types.ITEM_DETAILS_DELETE_ADD_DATA_RESPONSE,
  };
};

export const clearItemDetailsState = () => {
  return {
    type: types.CLEAR_ITEM_DETAILS_STATE,
  };
};

export const clearTransferState = () => {
  return {
    type: types.CLEAR_TRANSFER_STATE,
  };
};

export const scriptLoaded = (bool) => {
  return {
    type: types.SCRIPT_LOADED,
    payload: bool,
  };
};

export const addSfcData = (json) => {
  return {
    type: types.ADD_SFC_DATA,
    payload: json,
  };
};

export const deleteAllSFCInputs = () => {
  return {
    type: types.DELETE_ALL_SFC_INPUTS,
  };
};

export const changeSfcInputsStatus = (json) => {
  return {
    type: types.CHANGE_SFC_INPUTS_STATUS,
    payload: json,
  };
};

export const changeProductDataStatus = (json) => {
  return {
    type: types.CHANGE_PRODUCT_DATA_STATUS,
    payload: json,
  };
};

export const tagSensorAction = (json) => {
  return {
    type: types.TAG_SENSOR_INPUT,
    payload: json,
  };
};

export const tagSensorIDAction = (json) => {
  return {
    type: types.TAG_SENSOR_ID_INPUT,
    payload: json,
  };
};

export const showSubscribeModalAction = (bool) => {
  return {
    type: types.SHOW_SUBSCRIBE_MODAL,
    payload: bool,
  };
};

export const addFileUploads = (json) => {
  return {
    type: types.ITEM_DETAILS_ADD_FILE_UPLOAD,
    payload: json,
  };
};

export const clearFileUploadState = () => {
  return {
    type: types.CLEAR_FILE_UPLOAD_STATE,
  };
};

export const saveCurrentTab = (params) => {
  return {
    type: types.SAVE_TAB,
    payload: params,
  };
};

export const clearDownload = () => {
  return {
    type: types.CLEAR_DOWNLOAD,
  };
};

export const hideTwinPictures = (bool) => {
  return {
    type: types.HIDE_TWIN_PICTURES,
    payload: bool,
  };
};

export const hideFoodForm = (bool) => {
  return {
    type: types.HIDE_FOOD_FORM,
    payload: bool,
  };
};

export const hideStatus = (bool) => {
  return {
    type: types.HIDE_STATUS,
    payload: bool,
  };
};

export const hideData = (bool) => {
  return {
    type: types.HIDE_DATA,
    payload: bool,
  };
};

export const storeGroups = (group) => {
  return {
    type: types.STORE_GROUPS,
    payload: group,
  };
};

export const allowSharing = (bool) => {
  return {
    type: types.ALLOW_SHARING,
    payload: bool,
  };
};
